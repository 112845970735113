import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { IconDatabase } from 'tabler-icons';
import Select from 'atoms/select/Select';
import { useAuth } from 'services/UseAuth';
import styles from './DataSource.module.css';
import DataSourceSummary from './datasourcesummary/DataSourceSummary';

const DataSource = ({
  activeDataSource,
  dataSources,
  defaultYearFromDeepAnalysis,
  defaultYearToDeepAnalysis,
  disableMenuselection,
  setActiveDataSource,
  handleDataSourceChange,
}) => {
  const history = useHistory();

  const [forbund, setForbund] = useState(activeDataSource.forbund);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableForbund, setAvailableForbund] = useState([]);
  const [availableDSL1, setAvailableDSL1] = useState([]);
  const [availableDSL2, setAvailableDSL2] = useState([]);

  const { user } = useAuth();
  const { role } = user;

  const yearRange =
    defaultYearFromDeepAnalysis !== 0 &&
    defaultYearFromDeepAnalysis !== null &&
    defaultYearToDeepAnalysis - defaultYearFromDeepAnalysis > 1;

  const getAvailableSources = (source, rules) =>
    source.filter(item =>
      rules.every(({ property, expression }) => expression(item[property]))
    );

  const findSource = (source, rules) =>
    source.find(item =>
      rules.every(({ property, expression }) => expression(item[property]))
    );

  const inRange = (x, min, max) => (x - min) * (x - max) <= 0;

  const getYear = p => {
    const from = defaultYearFromDeepAnalysis;
    const to = defaultYearToDeepAnalysis;

    // defaultYearFromDeepAnalysis might be null, it might also be 0 in some cases
    const fromConfigured = from !== null && from !== 0;

    if (role === 'medlem' && fromConfigured && from !== to) {
      return inRange(p, from, to);
    }

    // If defaultYearFromDeepAnalysis is not configured members has to have the option
    // to search one year back in time, out of the configured range
    if (role === 'medlem') {
      return inRange(p, to - 1, to);
    }

    return p === activeDataSource.year;
  };

  // This function uses the findSource function recursively with a list of rules.
  // If the findSource function don't return a result for all of the defined rules
  // the function will try again with all rules except the last one.
  const sequentiallyFindSource = (arr, rules) => {
    let result;

    for (let i = 0; i < rules.length; i += 1) {
      result = findSource(arr, rules);
      if (!result) {
        rules.pop();
      }
      if (result) {
        break;
      }
    }

    // If no result, find the first source with the "DEFAULT" property set to 1
    if (!result) {
      result = findSource(arr, [
        { property: 'DEFAULT', expression: p => p === 1 },
      ]);
    }

    // If there's no default source configured, choose the first object in the array
    if (!result) [result] = arr;
    return result;
  };

  const setSource = source =>
    setActiveDataSource({
      forbund: source.FORBUND,
      dsl1: source.DSL1,
      dsl2: source.DSL2,
      measureVar: source.measure_var,
      year: source.YEAR,
    });

  const handleChange = (value, type) => {
    const previousDatasource = { ...activeDataSource };

    if (type === 'year') {
      handleDataSourceChange(value);
      const availableSources = getAvailableSources(dataSources, [
        { property: 'YEAR', expression: p => p === Number(value) },
      ]);

      const newSource = sequentiallyFindSource(availableSources, [
        { property: 'DSL1', expression: p => p === previousDatasource.dsl1 },
        {
          property: 'FORBUND',
          expression: p => p === previousDatasource.forbund,
        },
        { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
      ]);

      setSource(newSource);
    }

    // Helps members load the newest available source
    // Prevents 400-errors from lonereport endpoint
    // We're not sure if the 'SA'-union is configured the same way as the current union
    if (type === 'forbund') {
      // Two commented rules below caused errors. It tries to find sources that are
      // same as the chosen ones, which breaks if user changes datasource and there
      // aren't available sources from ex. same year or with the same dsl1.
      // Now it takes what it can get when forbund changes.
      const availableSources = getAvailableSources(dataSources, [
        { property: 'FORBUND', expression: p => p === value },
        // { property: 'YEAR', expression: p => getYear(p) },
        // { property: 'DSL1', expression: p => p === activeDataSource.dsl1 },
      ]).sort((a, b) => b?.YEAR - a?.YEAR);

      const newSource = sequentiallyFindSource(availableSources, [
        { property: 'DSL1', expression: p => p === previousDatasource.dsl1 },
        { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
      ]);

      setSource(newSource);
      setForbund(value);
    }

    if (type === 'dsl1') {
      const availableSources = getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        { property: 'DSL1', expression: p => p === value },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
      ]).sort((a, b) => {
        if (
          a.YEAR === activeDataSource.year &&
          b.YEAR !== activeDataSource.year
        ) {
          return -1;
        }
        if (
          a.YEAR !== activeDataSource.year &&
          b.YEAR === activeDataSource.year
        ) {
          return 1;
        }
        return b?.YEAR - a?.YEAR;
      });

      const newSource = sequentiallyFindSource(availableSources, [
        { property: 'YEAR', expression: p => p === previousDatasource.year },
        { property: 'DSL2', expression: p => p === previousDatasource.dsl2 },
      ]);

      setSource(newSource);
    }

    if (type === 'dsl2') {
      const availableSources = getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
        { property: 'DSL2', expression: p => p === value },
      ]).sort((a, b) => {
        if (
          a.YEAR === activeDataSource.year &&
          b.YEAR !== activeDataSource.year
        ) {
          return -1;
        }
        if (
          a.YEAR !== activeDataSource.year &&
          b.YEAR === activeDataSource.year
        ) {
          return 1;
        }
        return b?.YEAR - a?.YEAR;
      });

      let newSource;

      // Try an older source only if the user currently are viewing the default year
      // If not, the user should be redirected to the newest possible source
      if (role === 'medlem' && !yearRange) {
        newSource = sequentiallyFindSource(availableSources, [
          {
            property: 'YEAR',
            expression: p => {
              if (activeDataSource.year === defaultYearToDeepAnalysis) {
                return p >= defaultYearToDeepAnalysis - 1;
              }

              return p === defaultYearToDeepAnalysis;
            },
          },
        ]);
      } else {
        newSource = sequentiallyFindSource(availableSources, [
          {
            property: 'YEAR',
            expression: p => p === previousDatasource.year,
          },
        ]);
      }
      setSource(newSource);
    }
  };

  useEffect(() => {
    setAvailableForbund(
      dataSources.reduce((acc, curr) => {
        if (!acc.includes(curr.FORBUND)) acc.push(curr.FORBUND);
        return acc;
      }, [])
    );

    setAvailableYears(
      dataSources
        .filter(
          ds =>
            ds.DSL1 === activeDataSource.dsl1 &&
            ds.DSL2 === activeDataSource.dsl2
        )
        .reduce((acc, curr) => {
          if (!acc.includes(curr.YEAR)) acc.push(curr.YEAR);
          return acc;
        }, [])
        .filter(y => {
          if (
            role === 'medlem' &&
            defaultYearFromDeepAnalysis &&
            forbund !== 'SA'
          ) {
            return inRange(
              y,
              defaultYearFromDeepAnalysis,
              defaultYearToDeepAnalysis
            );
          }

          if (role === 'medlem') {
            return inRange(
              y,
              defaultYearToDeepAnalysis - 1,
              defaultYearToDeepAnalysis
            );
          }

          return y;
        })
        .sort()
    );
  }, [
    activeDataSource,
    role,
    dataSources,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    forbund,
  ]);

  useEffect(() => {
    setAvailableDSL1(
      getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
      ])
        .map(ds => {
          return {
            dsl: ds.DSL1,
            desc: ds.DSL1_DESC,
          };
        })
        .reduce((acc, curr) => {
          if (!acc.some(a => a.dsl === curr.dsl)) acc.push(curr);
          return acc;
        }, [])
    );
    // eslint-disable-next-line
  }, [
    activeDataSource.year,
    activeDataSource.forbund,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    role,
  ]);

  useEffect(() => {
    setAvailableDSL2(
      getAvailableSources(dataSources, [
        {
          property: 'FORBUND',
          expression: p => p === activeDataSource.forbund,
        },
        { property: 'DSL1', expression: p => p === activeDataSource.dsl1 },
        {
          property: 'YEAR',
          expression: p => getYear(p),
        },
      ])
        .sort((a, b) => b.YEAR - a.YEAR)
        .map(ds => {
          const lastYearsData = ds.YEAR !== activeDataSource.year;
          return {
            dsl: ds.DSL2,
            desc: lastYearsData ? `${ds.DSL2_DESC} - ${ds.YEAR}` : ds.DSL2_DESC,
            lastYearsData,
          };
        })
        .sort((a, b) => {
          // TODO: Do we need this?
          // if (activeDataSource.year < defaultYearToDeepAnalysis) {
          //   return b.lastYearsData - a.lastYearsData;
          // }
          return a.lastYearsData - b.lastYearsData;
        })
        .reduce((acc, curr) => {
          if (!acc.some(a => a.dsl === curr.dsl)) acc.push(curr);
          return acc;
        }, [])
        .filter(a => a.desc !== 'Okï¿½nt' && a.desc !== 'Okänt')
    );
    // eslint-disable-next-line
  }, [
    activeDataSource.year,
    activeDataSource.dsl1,
    activeDataSource.forbund,
    defaultYearFromDeepAnalysis,
    defaultYearToDeepAnalysis,
    role,
  ]);

  const allAvailableYears = () =>
    availableYears.map(item => ({
      key: item,
      value: item,
      desc: item,
    }));

  // TODO: se över logiken, utför i backend och returnera en array med optionsen bara i ett API-kall
  const getYearOptions = () => {
    if (role === 'medlem') {
      if (activeDataSource.forbund === 'CF') {
        return allAvailableYears();
      }
      const latestAvailableYear = availableYears[availableYears.length - 1];

      return [
        {
          key: latestAvailableYear,
          value: latestAvailableYear,
          desc: latestAvailableYear,
        },
      ];
    }

    return allAvailableYears();
  };

  return (
    <>
      <div id="datasources" className={styles.dataSource}>
        <h2 className={styles.dataSourceHeading}>
          <IconDatabase
            className={styles.indicatorSymbol}
            role="img"
            aria-label=""
          />
          <span className={styles.buttonText}>Statistikkällor</span>
        </h2>
        <DataSourceSummary activeDataSource={activeDataSource} />
        <div className={styles.dataSourceSelection}>
          <Select
            label="Statistikkälla"
            disabled={disableMenuselection || availableForbund.length < 2}
            id="forbund_select"
            onChange={evt => handleChange(evt.target.value, 'forbund')}
            options={availableForbund.map(item => ({
              key: item,
              value: item,
              desc: item === 'SA' ? 'Samtliga Sacoförbund' : 'Mitt förbund',
            }))}
            value={activeDataSource.forbund}
          />
        </div>
        <div className={styles.dataSourceSelection}>
          <Select
            label="Insamlingskälla"
            disabled={disableMenuselection || availableDSL1.length < 2}
            id="dsl1_select"
            onChange={evt => handleChange(evt.target.value, 'dsl1')}
            options={availableDSL1.map(({ desc, dsl }) => ({
              key: dsl,
              value: dsl,
              desc,
            }))}
            value={activeDataSource.dsl1}
          />
        </div>
        {availableDSL2.length > 0 && (
          <div className={styles.dataSourceSelection}>
            <Select
              label="Alternativ"
              disabled={disableMenuselection || availableDSL2.length < 2}
              id="dsl2_select"
              onChange={evt => handleChange(evt.target.value, 'dsl2')}
              options={availableDSL2.map(({ desc, dsl }) => ({
                key: dsl,
                value: dsl,
                desc,
              }))}
              value={activeDataSource.dsl2}
            />
          </div>
        )}
        {/* Dont let users change year, unless there's an interval configured */}
        {/* That is: defaultYearFromDeepAnalysis can't be null, zero or just defaultYearToDeepAnalysis - 1  */}
        {(role !== 'medlem' || (role === 'medlem' && yearRange)) && (
          <div className={styles.dataSourceSelection}>
            <Select
              label="År"
              disabled={disableMenuselection}
              id="ar_select"
              options={getYearOptions()}
              onChange={evt => handleChange(evt.target.value, 'year')}
              value={activeDataSource.year.toString()}
            />
          </div>
        )}
        <button
          type="button"
          className={styles.infoLink}
          onClick={() => history.push('#statistics')}
        >
          Läs om statistikkällorna
        </button>
      </div>
      <div className={styles.print}>
        <DataSourceSummary
          activeDataSource={activeDataSource}
          availableDSL1={availableDSL1}
          availableDSL2={availableDSL2}
        />
      </div>
    </>
  );
};

DataSource.propTypes = {
  activeDataSource: PropTypes.shape({
    forbund: PropTypes.string.isRequired,
    dsl1: PropTypes.string.isRequired,
    dsl2: PropTypes.string.isRequired,
    measureVar: PropTypes.arrayOf(String).isRequired,
    year: PropTypes.number.isRequired,
  }).isRequired,
  dataSources: PropTypes.arrayOf(Object).isRequired,
  defaultYearFromDeepAnalysis: PropTypes.number,
  defaultYearToDeepAnalysis: PropTypes.number.isRequired,
  disableMenuselection: PropTypes.bool.isRequired,
  setActiveDataSource: PropTypes.func.isRequired,
  handleDataSourceChange: PropTypes.func.isRequired,
};

DataSource.defaultProps = {
  defaultYearFromDeepAnalysis: null,
};

export default DataSource;
