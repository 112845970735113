import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'atoms/select/Select';
import styles from './Presentation.module.css';

const Presentation = ({
  allowCustomPresentation,
  presentationList,
  selectedPresentation,
  setCustomPresentation,
  setSelectedPresentation,
  fetchingData,
}) => {
  const [selectedBreakby, setSelectedBreakby] = useState('');

  const handleSelectedPresentation = e => {
    if (e.target.value === 'custom') {
      setCustomPresentation(true);
    } else {
      setCustomPresentation(false);
      const matchingPresentation = presentationList.find(
        pres => pres.BREAKBY_VAR === e.target.value
      );
      setSelectedPresentation([matchingPresentation]);
    }

    setSelectedBreakby(e.target.value);
  };

  useEffect(() => {
    if (
      selectedBreakby !== 'custom' &&
      presentationList &&
      presentationList.length > 0
    ) {
      const isSelectedBreakbyAvailable = presentationList.some(
        presentation => presentation.BREAKBY_VAR === selectedBreakby
      );

      if (isSelectedBreakbyAvailable) {
        setSelectedPresentation([
          presentationList.find(
            presentation => presentation.BREAKBY_VAR === selectedBreakby
          ),
        ]);
      } else {
        setSelectedPresentation([presentationList[0]]);
        setSelectedBreakby(presentationList[0].BREAKBY_VAR);
      }
    }
  }, [presentationList, selectedBreakby, setSelectedPresentation]);

  useEffect(() => {
    if (
      selectedPresentation.length === 1 &&
      selectedPresentation[0].BREAKBY_VAR &&
      selectedBreakby !== 'custom'
    ) {
      setSelectedBreakby(selectedPresentation[0].BREAKBY_VAR);
    }
  }, [selectedPresentation]);

  return (
    <div id="presentations" className={styles.presentation}>
      {presentationList && presentationList.length > 0 && selectedBreakby && (
        <Select
          disabled={fetchingData}
          id="presentations"
          label="Presentationssätt"
          shaded
          onChange={e => handleSelectedPresentation(e)}
          options={[
            ...presentationList.map(({ BREAKBY_VAR, REPORT_TEXT }) => ({
              key: BREAKBY_VAR,
              value: BREAKBY_VAR,
              desc: REPORT_TEXT,
            })),
            ...(presentationList.length > 1 && allowCustomPresentation
              ? [
                  {
                    key: 'custom',
                    value: 'custom',
                    desc: 'Egen rapport',
                  },
                ]
              : []),
          ]}
          value={selectedBreakby}
        />
      )}
    </div>
  );
};

Presentation.propTypes = {
  allowCustomPresentation: PropTypes.bool.isRequired,
  presentationList: PropTypes.arrayOf(Object),
  selectedPresentation: PropTypes.arrayOf(Object),
  setCustomPresentation: PropTypes.func.isRequired,
  setSelectedPresentation: PropTypes.func.isRequired,
  fetchingData: PropTypes.bool.isRequired,
};

Presentation.defaultProps = {
  presentationList: [],
  selectedPresentation: [],
};

export default Presentation;
